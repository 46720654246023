@import "~@infosight/elmer/dist/sass/variables"; @import "src/variables";
.full-access {
  font-size: $next-global-font-size;

  ul {
    margin-left: 2em;
    ul {
      margin-left: 2.5em;
      list-style-type: circle;
    }
  }
  .pl-twenty {
    padding-left: 20px;
  }
  .ul-circle {
    list-style: circle;
    margin-left: 18px;
    margin-bottom: 8px;
  }
  .li-disc {
    list-style: disc;
  }
  .mar-bot {
    margin-bottom: 14px;
  }
  ol {
    margin-left: 2em;
    ul {
      margin-left: 2.5em;
    }
    strong {
      display: block;
    }
  }
}

.full-access-banner {
  label {
    line-height: normal;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  button {
    cursor: pointer;
  }
}
